// Import fonts
@import '../fonts/Icons/WeasyStudio-Icons.css';
@import '../fonts/Mona-Sans/Mona-Sans.css';

// Import color scheme
@import 'theme/Scheme';

// Import generic refs
@import 'properties/Breakpoint';
@import 'properties/Fonts';
@import 'properties/Radius';
@import 'properties/Shadow';
@import 'properties/Spacing';
@import 'properties/Transition';
@import 'properties/Mixin';

// Import components refs
@import '../../components/common/Badge/Badge';
@import '../../components/common/Button/Button';
@import '../../components/common/Card/Card';
@import '../../components/common/Charts/LineChart/LineChart';
@import '../../components/common/ContextMenu/ContextMenu';
@import '../../components/common/DataGrid/DataGrid';
@import '../../components/common/Dialog/Dialog';
@import '../../components/common/Fields/Checkbox/Checkbox';
@import '../../components/common/Fields/Dropdown/Dropdown';
@import '../../components/common/Fields/FileUploader/FileUploader';
@import '../../components/common/Fields/Switch/Switch';
@import '../../components/common/Fields/Textfield/Textfield';
@import '../../components/common/Header/Header';
@import '../../components/common/Loader/Skeleton/Skeleton';
@import '../../components/common/Messages/NoData/NoData';
@import '../../components/common/Navbar/Navbar';
@import '../../components/common/Section/Section';
@import '../../components/common/Snackbar/Snackbar';
@import '../../components/common/Tab/Tab';
@import '../../components/common/Toolbar/Toolbar';
@import '../../components/common/Tooltip/Tooltip';

// Import routes refs
@import '../../routes/Login/Login';
@import '../../routes/Finance/Income-Expense/Income-Expense';
@import '../../routes/Finance/Income-Expense/PlayerData/PlayerData';
@import '../../routes/NotFound/NotFound';
@import '../../routes/Registry/Registry';
@import '../../routes/Registry/PlayerData/PlayerData';
@import '../../routes/Society/Teams';
@import '../../routes/Society/Staff';
@import '../../routes/Society/Data/TeamsData';
@import '../../routes/User/Settings/Settings';
@import '../../routes/User/Settings/Preferences/Preferences';
@import '../../routes/User/Settings/Profile/Profile';
@import '../../routes/User/Settings/Security/Security';
@import '../../routes/User/Settings/Security/Reset/ResetPassword';
@import '../../routes/User/NewUser/NewUser';

* {
  outline: none;
  box-sizing: border-box;
}

html, body {
  font-family: 'Mona Sans', sans-serif;
  scroll-behavior: smooth;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  //height: 100vh;
  background: var(--wg-background);
  color: var(--wg-neutral-10);
  &.no-overflow {
    overflow-y: hidden;
  }
}

// Scrollbar

::-webkit-scrollbar {
  width: 16px;
}

:not([no-y-overflow])::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: var(--wg-radius-100);
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: var(--wg-neutral-4);
}

::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: var(--wg-radius-100);
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: red;
}

a {
  text-decoration: none;
  font-size: var(--wg-font-02);
  font-weight: 500;
  color: var(--wg-primary);
  transition: color @transitionDefault ease-in-out;
  .disableSelection();
  &.wg-decoration {
    text-decoration: underline;
  }
  &.wg-reverse {
    color: var(--wg-onPrimary)
  }
  &:hover, &:focus {
    color: var(--wg-primaryLight);
  }
  &.disabled {
    pointer-events: none;
    color: var(--wg-neutral-4);
  }
}

i {
  font-size: 18px;
}

input {
  appearance: none;
  -webkit-appearance: none;
}

.wg-required {
  height: 16px;
  font-size: var(--wg-font-04);
  color: var(--wg-negative);
}

.wg-divider {
  width: 100%;
  height: 1px;
  background: var(--wg-neutral-1);
}

.wg-app {
  background: var(--wg-background);
  width: 100%;
  //height: 100vh;
  .wg-appContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--wg-spacing-06);
    position: relative;
    top: 60px;
    left: 296px;
    width: calc(~'100vw - 312px');
    height: calc(~'100vh - 60px');
    padding: var(--wg-spacing-07) var(--wg-spacing-08);
    transition: width, left @transitionDefault ease-out;
    &.collapsed {
      width: calc(~'100vw - 112px');
      left: 96px;
    }
  }

  .wg-pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;
    .wg-pageTitle {
      font-size: var(--wg-font-05);
      font-weight: 700;
      color: var(--wg-neutral-12);
    }
    .wg-pageActions {
      display: flex;
      gap: var(--wg-spacing-03);
    }
  }
}

@media screen and (max-width: @smallLaptop) {
  .wg-app {
    .wg-appContent {
      width: calc(~'100vw - 112px');
      left: 96px;
      padding: var(--wg-spacing-08);
    }
    &.collapsed {
      .wg-appContent {
        width: calc(~'100vw - 96px');
        left: 96px;
      }
    }
  }
}

@media screen and (max-width: @tablet) {
  .wg-app {
    .wg-appContent {
      left: 0;
      width: 100vw;
      padding: var(--wg-spacing-04);
    }
    &.collapsed {
      left: 0;
      width: 100vw;
      .wg-appContent {
        left: 96px;
      }
    }
  }
}

@media screen and(max-width: @largeMobile) {
  .wg-app {
    .wg-appContent {
      .wg-pageHeader {
        height: inherit;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: var(--wg-spacing-04);
      }
    }
  }
}