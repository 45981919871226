// Radius Refs

.wg-radius-default {
  --wg-radius-00: 2px;
  --wg-radius-01: 4px;
  --wg-radius-02: 6px;
  --wg-radius-03: 8px;
  --wg-radius-04: 10px;
  --wg-radius-05: 12px;
  --wg-radius-100: 10000px;
}

.wg-radius-compact {
  --wg-radius-00: 2px;
  --wg-radius-01: 4px;
  --wg-radius-02: 6px;
  --wg-radius-03: 8px;
  --wg-radius-04: 10px;
  --wg-radius-05: 12px;
  --wg-radius-100: 10000px;
}