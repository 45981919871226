// Palette Scheme

:root {
  --wg-primary: #000000;
  --wg-primaryDark: #000000;
  --wg-primaryLight: #000000;
  --wg-onPrimary: #000000;
  --wg-background: #000000;
  --wg-surface: #000000;
  --wg-surfaceVariant: #000000;
  --wg-neutral-0: #000000;
  --wg-neutral-1: #000000;
  --wg-neutral-2: #000000;
  --wg-neutral-3: #000000;
  --wg-neutral-4: #000000;
  --wg-neutral-5: #000000;
  --wg-neutral-6: #000000;
  --wg-neutral-10: #000000;
  --wg-neutral-12: #000000;
  --wg-positive: #000000;
  --wg-positiveLight: #000000;
  --wg-warning: #000000;
  --wg-warningLight: #000000;
  --wg-negative: #000000;
  --wg-negativeLight: #000000;
  --wg-chart01: #000000;
  --wg-chart02: #000000;
  --wg-chart03: #000000;
  --wg-chart04: #000000;
  --wg-shadowLg: rgba(0,0,0,1);
  --wg-overlay: rgba(0,0,0,1);
}

@import "./ThemeSwitcher";