@import '../fonts/Icons/WeasyStudio-Icons.css';
@import '../fonts/Mona-Sans/Mona-Sans.css';
:root {
  --wg-primary: #000000;
  --wg-primaryDark: #000000;
  --wg-primaryLight: #000000;
  --wg-onPrimary: #000000;
  --wg-background: #000000;
  --wg-surface: #000000;
  --wg-surfaceVariant: #000000;
  --wg-neutral-0: #000000;
  --wg-neutral-1: #000000;
  --wg-neutral-2: #000000;
  --wg-neutral-3: #000000;
  --wg-neutral-4: #000000;
  --wg-neutral-5: #000000;
  --wg-neutral-6: #000000;
  --wg-neutral-10: #000000;
  --wg-neutral-12: #000000;
  --wg-positive: #000000;
  --wg-positiveLight: #000000;
  --wg-warning: #000000;
  --wg-warningLight: #000000;
  --wg-negative: #000000;
  --wg-negativeLight: #000000;
  --wg-chart01: #000000;
  --wg-chart02: #000000;
  --wg-chart03: #000000;
  --wg-chart04: #000000;
  --wg-shadowLg: #000000;
  --wg-overlay: #000000;
}
.wg-theme-light {
  --wg-primary: #0D0C22;
  --wg-primaryDark: #04040D;
  --wg-primaryLight: #201E45;
  --wg-onPrimary: #FFFFFF;
  --wg-background: #F7F8FA;
  --wg-surface: #FFFFFF;
  --wg-surfaceVariant: #FAFAFE;
  --wg-neutral-0: #FFFFFF;
  --wg-neutral-1: #EFF1F4;
  --wg-neutral-2: #DBE0E8;
  --wg-neutral-3: #BCBEC9;
  --wg-neutral-4: #969FAE;
  --wg-neutral-5: #686576;
  --wg-neutral-6: #5D5777;
  --wg-neutral-10: #3E5060;
  --wg-neutral-12: #0D0C22;
  --wg-positive: #007654;
  --wg-positiveLight: #C9F1E8;
  --wg-warning: #A84F01;
  --wg-warningLight: #FFEFD6;
  --wg-negative: #CE0000;
  --wg-negativeLight: #FFDCDC;
  --wg-chart01: #F6C543;
  --wg-chart02: #4E13A6;
  --wg-chart03: #CF3169;
  --wg-chart04: #50B1D4;
  --wg-shadowLg: rgba(0, 0, 0, 0.08);
  --wg-overlay: rgba(0, 0, 0, 0.5);
  --toastify-color-info: var(--wg-primary);
  --toastify-color-warning: var(--wg-warning);
  --toastify-color-error: var(--wg-negative);
}
.wg-theme-dark {
  --wg-primary: #0D0C22;
  --wg-primaryDark: #04040D;
  --wg-primaryLight: #201E45;
  --wg-onPrimary: #FFFFFF;
  --wg-background: #0b0e1e;
  --wg-surface: #FFFFFF;
  --wg-surfaceVariant: #FAFAFE;
  --wg-neutral-0: #FFFFFF;
  --wg-neutral-1: #EFF1F4;
  --wg-neutral-2: #DBE0E8;
  --wg-neutral-3: #BCBEC9;
  --wg-neutral-4: #969FAE;
  --wg-neutral-5: #686576;
  --wg-neutral-6: #5D5777;
  --wg-neutral-10: #3E5060;
  --wg-neutral-12: #0D0C22;
  --wg-positive: #007654;
  --wg-positiveLight: #C9F1E8;
  --wg-warning: #A84F01;
  --wg-warningLight: #FFEFD6;
  --wg-negative: #CE0000;
  --wg-negativeLight: #FFDCDC;
  --wg-chart01: #F6C543;
  --wg-chart02: #4E13A6;
  --wg-chart03: #CF3169;
  --wg-chart04: #50B1D4;
  --wg-shadowLg: rgba(0, 0, 0, 0.08);
  --wg-overlay: rgba(0, 0, 0, 0.5);
  --toastify-color-info: var(--wg-primary);
  --toastify-color-warning: var(--wg-warning);
  --toastify-color-error: var(--wg-negative);
}
.wg-font-default {
  --wg-font-00: 10px;
  --wg-font-01: 12px;
  --wg-font-02: 14px;
  --wg-font-03: 16px;
  --wg-font-04: 18px;
  --wg-font-05: 20px;
  --wg-font-06: 24px;
  --wg-font-07: 28px;
  --wg-font-08: 32px;
  --wg-font-09: 36px;
  --wg-font-10: 40px;
  --wg-font-11: 44px;
}
.wg-font-compact {
  --wg-font-00: 10px;
  --wg-font-01: 12px;
  --wg-font-02: 14px;
  --wg-font-03: 16px;
  --wg-font-04: 18px;
  --wg-font-05: 20px;
  --wg-font-06: 24px;
  --wg-font-07: 28px;
  --wg-font-08: 32px;
  --wg-font-09: 36px;
  --wg-font-10: 40px;
  --wg-font-11: 44px;
}
.wg-radius-default {
  --wg-radius-00: 2px;
  --wg-radius-01: 4px;
  --wg-radius-02: 6px;
  --wg-radius-03: 8px;
  --wg-radius-04: 10px;
  --wg-radius-05: 12px;
  --wg-radius-100: 10000px;
}
.wg-radius-compact {
  --wg-radius-00: 2px;
  --wg-radius-01: 4px;
  --wg-radius-02: 6px;
  --wg-radius-03: 8px;
  --wg-radius-04: 10px;
  --wg-radius-05: 12px;
  --wg-radius-100: 10000px;
}
.wg-spacing-default {
  --wg-spacing-00: 2px;
  --wg-spacing-01: 4px;
  --wg-spacing-02: 8px;
  --wg-spacing-03: 12px;
  --wg-spacing-04: 16px;
  --wg-spacing-05: 20px;
  --wg-spacing-06: 24px;
  --wg-spacing-07: 28px;
  --wg-spacing-08: 32px;
  --wg-spacing-09: 36px;
  --wg-spacing-10: 40px;
  --wg-spacing-11: 44px;
  --wg-spacing-12: 48px;
}
.wg-spacing-compact {
  --wg-spacing-00: 2px;
  --wg-spacing-01: 4px;
  --wg-spacing-02: 8px;
  --wg-spacing-03: 12px;
  --wg-spacing-04: 16px;
  --wg-spacing-05: 20px;
  --wg-spacing-06: 24px;
  --wg-spacing-07: 28px;
  --wg-spacing-08: 32px;
  --wg-spacing-09: 36px;
  --wg-spacing-10: 40px;
  --wg-spacing-11: 44px;
  --wg-spacing-12: 48px;
}
.wg-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 44px;
  padding: var(--wg-spacing-02) var(--wg-spacing-03);
  background: var(--wg-neutral-2);
  color: var(--wg-neutral-10);
  font-weight: 500;
  border-radius: var(--wg-radius-02);
  text-transform: capitalize;
}
.wg-badge.neutral {
  background: var(--wg-neutral-2);
  color: var(--wg-neutral-10);
}
.wg-badge.positive {
  background: var(--wg-positiveLight);
  color: var(--wg-positive);
}
.wg-badge.warning {
  background: var(--wg-warningLight);
  color: var(--wg-warning);
}
.wg-badge.negative {
  background: var(--wg-negativeLight);
  color: var(--wg-negative);
}
.wg-badge.small {
  height: 36px;
}
.wg-badge.xsmall {
  padding: var(--wg-spacing-02);
  height: 28px;
  font-size: var(--wg-font-01);
}
.wg-badge .wg-badgeTooltip {
  position: absolute;
  top: 100%;
  left: -12px;
  width: max-content;
  margin-top: -4px;
  padding: var(--wg-spacing-03);
  background: var(--wg-primary);
  color: var(--wg-onPrimary);
  opacity: 0;
  pointer-events: none;
  z-index: 9;
  border-radius: var(--wg-radius-02);
  text-transform: none;
  transition: opacity 0.15s ease-in;
}
.wg-badge:hover .wg-badgeTooltip {
  opacity: 1;
}
.wg-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: var(--wg-spacing-02);
  position: relative;
  padding: 0 var(--wg-spacing-06);
  height: 44px;
  background: var(--wg-primary);
  font-size: var(--wg-font-02);
  font-weight: 500;
  color: var(--wg-onPrimary);
  text-transform: capitalize;
  border-radius: var(--wg-radius-100);
  cursor: pointer;
  transition: background 0.15s ease-out;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.wg-button i {
  font-size: var(--wg-font-05);
}
.wg-button i.wg-loadingIcon {
  animation: loadingIcon 1.25s infinite;
}
.wg-button:hover,
.wg-button:focus {
  background: var(--wg-primaryLight);
}
.wg-button.secondary {
  background: transparent;
  border: 1px solid var(--wg-neutral-2);
  color: var(--wg-primary);
}
.wg-button.secondary:hover {
  background: var(--wg-neutral-1);
  color: var(--wg-primary);
}
.wg-button.solid {
  background: var(--wg-primary);
  color: var(--wg-onPrimary);
}
.wg-button.solid:hover {
  background: var(--wg-primaryLight);
}
.wg-button.link {
  background: none;
  color: var(--wg-primary);
  padding: 0;
  text-decoration: underline;
}
.wg-button.link:hover {
  color: var(--wg-primaryLight);
}
.wg-button.danger {
  background: var(--wg-negativeLight);
  color: var(--wg-negative);
}
.wg-button.danger:hover {
  background: var(--wg-negative);
  color: var(--wg-onPrimary);
}
.wg-button.reverse {
  color: var(--wg-onPrimary);
}
.wg-button.icon {
  background: transparent;
  border: 0;
  color: var(--wg-primary);
}
.wg-button.icon:hover {
  background: var(--wg-primary);
  color: var(--wg-onPrimary);
}
.wg-button.disabled {
  background: var(--wg-neutral-1) !important;
  color: var(--wg-neutral-4) !important;
  pointer-events: none;
}
.wg-button.pressed {
  background: var(--wg-primary) !important;
  color: var(--wg-onPrimary) !important;
}
.wg-button.loading {
  pointer-events: none;
}
.wg-button.small {
  height: 36px;
}
.wg-button.small.icon {
  padding: 0;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
}
.wg-button.large {
  height: 56px;
}
.wg-button.leftAlign {
  position: absolute;
  left: 0;
}
@keyframes loadingIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.wg-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  gap: var(--wg-spacing-04);
  padding: var(--wg-spacing-06) var(--wg-spacing-06) var(--wg-spacing-06) var(--wg-spacing-06);
  background: var(--wg-surface);
  cursor: pointer;
  border-radius: var(--wg-radius-05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: 0.15s box-shadow ease-out;
}
.wg-card:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}
.wg-lineChartContainer .wg-lineChart {
  display: flex;
  width: 100%;
  height: 10px;
  background: var(--wg-neutral-1);
  border-radius: var(--wg-radius-100);
  overflow: hidden;
}
.wg-lineChartContainer .wg-lineChart .wg-line {
  width: 0;
  height: 100%;
  background: var(--wg-positive);
  transition: width 0.25s ease-in-out;
}
.wg-lineChartContainer .wg-lineChart .wg-line.minWidth {
  min-width: 10px;
}
.wg-lineChartContainer .wg-lineChart .wg-line.wg-lineChart_0 {
  background: var(--wg-chart01);
}
.wg-lineChartContainer .wg-lineChart .wg-line.wg-lineChart_1 {
  background: var(--wg-chart02);
}
.wg-lineChartContainer .wg-lineChart .wg-line.wg-lineChart_2 {
  background: var(--wg-chart03);
}
.wg-lineChartContainer .wg-lineChart .wg-line.wg-lineChart_3 {
  background: var(--wg-chart04);
}
.wg-contextMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  width: 240px;
  padding: var(--wg-spacing-01);
  background: var(--wg-surface);
  border-radius: var(--wg-radius-05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  z-index: 99;
  transition: all 0.15s ease-in-out;
  animation: showContextMenu 0.15s forwards;
}
.wg-contextMenu ul {
  margin: 0;
  padding: 0;
  width: 100%;
}
.wg-contextMenu ul li {
  list-style: none;
}
.wg-contextMenu ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-03);
  width: 100%;
  color: var(--wg-neutral-10);
  padding: var(--wg-spacing-03) var(--wg-spacing-03);
  border-radius: var(--wg-radius-02);
  transition: all 0.15s ease-out;
}
.wg-contextMenu ul li a i {
  font-size: 20px;
}
.wg-contextMenu ul li a:hover {
  background: var(--wg-neutral-1);
  font-weight: 500;
}
.wg-contextMenuItemProcessing.loading i {
  animation: loadingIcon 1.25s infinite;
}
.wg-contextMenuFakeOverlay {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
}
.wg-contextMenuFakeOverlay.visible {
  display: block;
}
@keyframes showContextMenu {
  0% {
    top: 40px;
    opacity: 0;
    pointer-events: none;
  }
  100% {
    top: 50px;
    opacity: 1;
    pointer-events: inherit;
  }
}
@keyframes loadingIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 450px) {
  .wg-contextMenu {
    left: 0;
  }
}
.wg-dataGrid {
  width: 100%;
}
.wg-dataGrid .wg-dataGridContentContainer {
  width: 100%;
  overflow-x: auto;
  padding-bottom: var(--wg-spacing-06);
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridHeader .wg-dataGridRow .wg-dataGridCell {
  height: 44px;
  border-top: 1px solid var(--wg-neutral-1);
  font-size: var(--wg-font-01);
  font-weight: 600;
  color: var(--wg-neutral-10);
  text-transform: uppercase;
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody {
  margin-top: var(--wg-spacing-01);
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow {
  margin-bottom: 2px;
  height: 62px;
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow .wg-dataGridCell {
  height: 62px;
  font-weight: 500;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow .wg-dataGridCell:first-child {
  border-top-left-radius: var(--wg-radius-01);
  border-bottom-left-radius: var(--wg-radius-01);
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow .wg-dataGridCell:last-child {
  border-top-right-radius: var(--wg-radius-01);
  border-bottom-right-radius: var(--wg-radius-02);
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow:last-child .wg-dataGridCell {
  border-bottom: none;
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow .wg-cellGroup {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-01);
  width: 100%;
  overflow: hidden;
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow .wg-cellGroup .description {
  font-size: var(--wg-font-01);
  font-weight: 500;
  color: var(--wg-neutral-4);
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow:hover .wg-dataGridCell {
  background: var(--wg-neutral-1);
}
.wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow .noLoaded {
  display: none;
}
.wg-dataGrid .wg-dataGridRow {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--wg-neutral-1);
}
.wg-dataGrid .wg-dataGridRow .wg-dataGridCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 200px;
  padding-left: var(--wg-spacing-03);
  padding-right: var(--wg-spacing-03);
  font-size: var(--wg-font-02);
  transition: background-color 0.1s;
  cursor: default;
}
.wg-dataGrid .wg-dataGridRow .wg-dataGridCell .wg-dataGridCellText {
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wg-dataGrid .wg-dataGridRow .wg-dataGridCell .hidden {
  opacity: 0;
  pointer-events: none;
}
.wg-dataGrid .wg-dataGridRow .wg-dataGridCell.checkbox {
  min-width: 48px;
  max-width: 48px;
}
.wg-dataGrid .wg-dataGridRow .wg-dataGridCell.avatar {
  min-width: 48px;
  max-width: 48px;
}
.wg-dataGrid .wg-dataGridRow .wg-dataGridCell.avatar .wg-dataGridCellAvatar {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  margin-right: var(--wg-spacing-03);
  border-radius: var(--wg-radius-100);
  overflow: hidden;
}
.wg-dataGrid .wg-dataGridRow .wg-dataGridCell.avatar .wg-dataGridCellAvatar img {
  width: 100%;
  height: auto;
}
.wg-dataGrid .wg-dataGridRow .wg-dataGridCell.deleteRow {
  width: 120px;
  min-width: 120px;
}
.wg-dataGrid .wg-dataGridRow .wg-dataGridCell.deleteRow {
  width: 120px;
  min-width: 120px;
}
.wg-dataGrid .wg-gridFilterArea {
  display: flex;
  gap: var(--wg-spacing-03);
  margin: var(--wg-spacing-01) var(--wg-spacing-01) var(--wg-spacing-06) var(--wg-spacing-01);
}
.wg-dataGrid .wg-noFilteredData {
  width: 100%;
  text-align: center;
}
.wg-layout-compact .wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow {
  height: 48px;
}
.wg-layout-compact .wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow .wg-dataGridCell {
  height: 44px;
}
.wg-layout-compact .wg-dataGrid .wg-dataGridContentContainer .wg-dataGridBody .wg-dataGridRow .wg-dataGridCell .wg-dataGridCellAvatar {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
}
@media screen and (max-width: 768px) {
  .wg-dataGrid .wg-gridFilterArea {
    flex-direction: column;
  }
}
.wg-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  z-index: 9999;
}
.wg-dialog.visible {
  animation: showDialog 0.15s forwards;
}
.wg-dialog .wg-dialogContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  gap: var(--wg-spacing-04);
  min-width: 400px;
  background: var(--wg-surface);
  padding: var(--wg-spacing-06) var(--wg-spacing-06) var(--wg-spacing-04) var(--wg-spacing-06);
  border-radius: var(--wg-radius-05);
  z-index: 9999;
}
.wg-dialog .wg-dialogContent .wg-dialogHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--wg-spacing-02);
  width: 100%;
  padding-bottom: var(--wg-spacing-06);
  font-size: var(--wg-font-03);
  font-weight: 600;
  border-bottom: 1px solid var(--wg-neutral-1);
}
.wg-dialog .wg-dialogContent .wg-dialogHeader .wg-dialogTitle {
  font-size: var(--wg-font-03);
  font-weight: 600;
  color: var(--wg-neutral-12);
}
.wg-dialog .wg-dialogContent .wg-dialogBody {
  width: 100%;
}
.wg-dialog .wg-dialogContent .wg-dialogFooter {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-03);
  position: relative;
  width: 100%;
  padding-top: var(--wg-spacing-03);
}
.wg-dialogOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--wg-neutral-12);
  opacity: 0.3;
  z-index: 999;
}
@keyframes showDialog {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: inherit;
  }
}
@media screen and (max-width: 768px) {
  .wg-dialog .wg-dialogContent {
    min-width: 320px;
    width: 100% !important;
  }
}
.wg-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-02);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.wg-checkbox .wg-checkArea {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wg-checkbox .wg-checkArea input {
  width: 20px;
  height: 20px;
  border: 1px solid var(--wg-neutral-2);
  border-radius: var(--wg-radius-01);
  cursor: pointer;
  transition: all 0.15s ease-out;
}
.wg-checkbox .wg-checkArea i {
  position: absolute;
  left: 50%;
  top: 4px;
  margin-left: -9px;
  opacity: 0;
  pointer-events: none;
  color: var(--wg-onPrimary);
  transform: rotate(-45deg);
  transition: all 0.15s ease-out;
}
.wg-checkbox .wg-checkArea input:checked {
  background: var(--wg-primary);
  border: 1px solid var(--wg-primary);
}
.wg-checkbox .wg-checkArea input:checked ~ i {
  opacity: 1;
  transform: rotate(0);
}
.wg-checkbox .wg-checkArea input:checked:hover {
  background: var(--wg-primaryLight);
  border: 1px solid var(--wg-primaryLight);
}
.wg-checkbox .wg-checkArea input:hover {
  border: 1px solid var(--wg-neutral-4);
}
.wg-checkbox .wg-checkArea input:focus {
  border: 1px solid var(--wg-neutral-10);
}
.wg-checkbox label {
  font-size: var(--wg-font-02);
  color: var(--wg-neutral-10);
}
.wg-checkbox.disabled {
  pointer-events: none;
}
.wg-checkbox.disabled input {
  background: var(--wg-neutral-1) !important;
  border: 1px solid var(--wg-neutral-1) !important;
}
.wg-checkbox.disabled label {
  color: var(--wg-neutral-3);
}
.wg-checkbox.small .wg-checkArea input {
  padding: var(--wg-spacing-01);
}
.wg-dropdown {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  cursor: default;
}
.wg-dropdown label {
  display: flex;
  align-items: flex-start;
  gap: var(--wg-spacing-01);
  padding-bottom: var(--wg-spacing-02);
  font-size: var(--wg-font-02);
  font-weight: 600;
  color: var(--wg-neutral-10);
}
.wg-dropdown input {
  width: 100%;
  height: 44px;
  padding: var(--wg-spacing-04);
  border: 1px solid var(--wg-neutral-2);
  font-size: var(--wg-font-02);
  font-weight: 400;
  color: var(--wg-neutral-10);
  border-radius: var(--wg-radius-02);
  text-transform: capitalize;
  pointer-events: none;
  box-shadow: none;
  transition: all 0.15s ease-out;
}
.wg-dropdown input::placeholder {
  color: var(--wg-neutral-4);
}
.wg-dropdown input:hover {
  border: 1px solid var(--wg-neutral-4);
}
.wg-dropdown input:focus,
.wg-dropdown input.focus {
  border: 1.5px solid var(--wg-neutral-10);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
}
.wg-dropdown .wg-field {
  position: relative;
  cursor: pointer;
}
.wg-dropdown .wg-field i {
  position: absolute;
  top: 50%;
  right: var(--wg-spacing-02);
  margin-top: -13px;
  font-size: var(--wg-font-07);
  color: var(--wg-neutral-10);
  pointer-events: none;
}
.wg-dropdown .wg-error {
  display: block;
  margin-top: var(--wg-spacing-01);
  color: var(--wg-negative);
}
.wg-dropdown.large input {
  height: 56px;
  border-radius: var(--wg-radius-05);
}
.wg-dropdown.disabled {
  pointer-events: none;
}
.wg-dropdown.disabled input {
  background: var(--wg-neutral-1);
  border: 1px solid var(--wg-neutral-1);
}
.wg-dropdown .wg-dropdownList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 90%;
  right: 0;
  width: 100%;
  max-height: 300px;
  padding: var(--wg-spacing-01);
  background: var(--wg-surface);
  border-radius: var(--wg-radius-05);
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--wg-neutral-2);
  opacity: 0;
  pointer-events: none;
  overflow: auto;
  z-index: 999;
  transition: all 0.15s ease-in-out;
  animation: showContextMenu 0.15s forwards;
}
.wg-dropdown .wg-dropdownList.open {
  animation: showDropdown 0.15s forwards;
}
.wg-dropdown .wg-dropdownList ul {
  margin: 0;
  padding: 0;
  width: 100%;
}
.wg-dropdown .wg-dropdownList ul li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-03);
  width: 100%;
  color: var(--wg-neutral-10);
  padding: var(--wg-spacing-03) var(--wg-spacing-03);
  border-radius: var(--wg-radius-03);
  text-transform: capitalize;
  transition: all 0.15s ease-out;
}
.wg-dropdown .wg-dropdownList ul li:hover {
  background: var(--wg-neutral-1);
  font-weight: 500;
}
.wg-dropdown .wg-dropdownList ul li.selected {
  background: var(--wg-primary);
  color: var(--wg-onPrimary);
  font-weight: 500;
}
.wg-dropdown .wg-dropdown_filter {
  position: relative;
  width: 100%;
}
.wg-dropdown .wg-dropdown_filter input {
  border: 0;
  border-bottom: 1px solid var(--wg-neutral-2);
  border-radius: 0;
  padding-left: var(--wg-spacing-06);
}
.wg-dropdown .wg-dropdown_filter input:focus {
  background: none;
  border: none;
  border-bottom: 1px solid var(--wg-neutral-2);
  box-shadow: none;
}
.wg-dropdown .wg-dropdown_filter i {
  position: absolute;
  top: 50%;
  margin-top: -9px;
}
@keyframes showDropdown {
  0% {
    top: 90%;
    opacity: 0;
    pointer-events: none;
  }
  100% {
    top: calc(100% + 6px);
    opacity: 1;
    pointer-events: inherit;
  }
}
.wg-fileUploader {
  width: 100%;
}
.wg-fileUploader .wg-fileUploaderArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--wg-spacing-03);
  align-items: center;
  width: 100%;
  min-height: 200px;
  background: var(--wg-surface);
  border: 1px dashed var(--wg-neutral-2);
  border-radius: var(--wg-radius-05);
  cursor: pointer;
  transition: border 0.15s ease-in-out;
}
.wg-fileUploader .wg-fileUploaderArea i {
  font-size: var(--wg-font-10);
}
.wg-fileUploader .wg-fileUploaderArea:hover {
  border: 1px dashed var(--wg-neutral-4);
}
.wg-fileUploader .wg-fileUploaderArea.onDrop {
  border: 1px dashed var(--wg-neutral-12);
}
.wg-fileUploader .wg-fileUploaderMetaData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--wg-spacing-02);
  margin-bottom: var(--wg-spacing-06);
  font-size: var(--wg-font-01);
}
.wg-fileUploader .wg-fileUploaderMetaData .wg-supportedExtensions span {
  text-transform: uppercase;
}
.wg-fileUploader .wg-fileUploaderAreaChildren {
  margin-top: var(--wg-spacing-01);
}
.wg-fileUploader .wg-fileList {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--wg-spacing-03);
}
.wg-fileUploader .wg-fileList .wg-file {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--wg-spacing-04);
  padding: var(--wg-spacing-06);
  width: 100%;
  background: var(--wg-neutral-1);
  border-radius: var(--wg-radius-05);
}
.wg-fileUploader .wg-fileList .wg-file .wg-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--wg-spacing-02);
  width: 100%;
  font-weight: 600;
}
.wg-fileUploader .wg-fileList .wg-file .wg-title .wg-fileHeader {
  display: flex;
  align-items: flex-start;
  gap: var(--wg-spacing-03);
}
.wg-fileUploader .wg-fileList .wg-file .wg-title .wg-fileHeader i {
  font-size: 24px;
}
.wg-fileUploader .wg-fileList .wg-file .wg-title .wg-fileHeader .wg-fileMeta {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-01);
}
.wg-fileUploader .wg-fileList .wg-file .wg-title .wg-fileHeader .wg-fileMeta .wg-fileSize {
  font-size: var(--wg-font-01);
  font-weight: 400;
}
.wg-fileUploader .wg-fileList .wg-file .wg-deleteFile {
  margin-top: -8px;
}
@media screen and (max-width: 768px) {
  .wg-fileUploaderArea {
    width: 100% !important;
  }
  .wg-fileUploaderMetaData {
    flex-direction: column;
    gap: 4px;
  }
}
.wg-switch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-02);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.wg-switch .wg-checkArea {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
}
.wg-switch .wg-checkArea input {
  position: relative;
  width: 48px;
  height: 24px;
  border: 1px solid var(--wg-neutral-2);
  border-radius: var(--wg-radius-100);
  transition: all 0.15s ease-out;
}
.wg-switch .wg-checkArea input::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  background: var(--wg-neutral-10);
  border-radius: var(--wg-radius-100);
  transition: all 0.15s ease-out;
}
.wg-switch .wg-checkArea input:checked {
  background: var(--wg-primary);
  border: 1px solid var(--wg-primary);
}
.wg-switch .wg-checkArea input:checked:hover {
  background: var(--wg-primaryLight);
  border: 1px solid var(--wg-primaryLight);
}
.wg-switch .wg-checkArea input:checked::before {
  background: var(--wg-onPrimary);
  left: calc(100% - 20px);
}
.wg-switch .wg-checkArea input:hover {
  border: 1px solid var(--wg-neutral-4);
}
.wg-switch .wg-checkArea input:focus {
  border: 1px solid var(--wg-neutral-10);
}
.wg-switch label {
  color: var(--wg-neutral-10);
  font-size: var(--wg-font-02);
  font-weight: 500;
  cursor: pointer;
}
.wg-switch.disabled {
  pointer-events: none;
}
.wg-switch.disabled input {
  background: var(--wg-neutral-2) !important;
  border: 1px solid var(--wg-neutral-1) !important;
}
.wg-switch.disabled input::before {
  background: var(--wg-neutral-4);
}
.wg-switch.disabled label {
  color: var(--wg-neutral-3);
}
.wg-textfield {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
}
.wg-textfield.hidden {
  opacity: 0;
  pointer-events: none;
}
.wg-textfield label {
  display: flex;
  align-items: flex-start;
  gap: var(--wg-spacing-01);
  padding-bottom: var(--wg-spacing-02);
  font-size: var(--wg-font-02);
  font-weight: 600;
  color: var(--wg-neutral-10);
  text-transform: capitalize;
}
.wg-textfield input {
  width: 100%;
  height: 44px;
  padding: var(--wg-spacing-04);
  border: 1px solid var(--wg-neutral-2);
  font-size: var(--wg-font-02);
  font-weight: 400;
  color: var(--wg-neutral-10);
  border-radius: var(--wg-radius-02);
  box-shadow: none;
  transition: all 0.15s ease-out;
}
.wg-textfield input::placeholder {
  color: var(--wg-neutral-4);
}
.wg-textfield input:hover {
  border: 1px solid var(--wg-neutral-4);
}
.wg-textfield input:focus {
  border: 1.5px solid var(--wg-neutral-10);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
}
.wg-textfield .wg-error {
  display: block;
  margin-top: var(--wg-spacing-01);
  color: var(--wg-negative);
}
.wg-textfield .wg-textfieldAction {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 36px;
  padding-right: var(--wg-spacing-03);
}
.wg-textfield .wg-textfieldAction i {
  font-size: var(--wg-font-05);
  color: var(--wg-primary);
}
.wg-textfield .wg-textfieldAction.disabled {
  pointer-events: none;
}
.wg-textfield .wg-textfieldAction.disabled i {
  color: var(--wg-neutral-4);
}
.wg-textfield.large input {
  height: 56px;
  border-radius: var(--wg-radius-05);
}
.wg-textfield.disabled {
  pointer-events: none;
}
.wg-textfield.disabled input {
  background: var(--wg-neutral-1);
  border: 1px solid var(--wg-neutral-1);
}
.wg-textfield.invalid input {
  border-color: var(--wg-negative);
  box-shadow: 0 0 0 4px rgba(217, 20, 13, 0.1);
}
.wg-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 60px;
  padding: 0 var(--wg-spacing-07);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--wg-background);
  border-bottom: 1px solid var(--wg-neutral-1);
  z-index: 999;
}
.wg-header .wg-leftSide {
  display: flex;
  align-items: center;
  gap: 0 12px;
}
.wg-header .wg-leftSide .wg-toggleMenu {
  padding: var(--wg-spacing-03);
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  border-radius: var(--wg-radius-01);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.wg-header .wg-leftSide .wg-toggleMenu:hover {
  background: var(--wg-neutral-1);
}
.wg-header .wg-leftSide .wg-toggleMenu i {
  font-size: 22px;
  color: var(--wg-neutral-10);
}
.wg-header .wg-rightSide {
  display: flex;
}
.wg-header .wg-rightSide .wg-profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-03);
  position: relative;
  padding: var(--wg-spacing-01);
  background: transparent;
  cursor: pointer;
  border-radius: var(--wg-radius-01);
  transition: background 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.wg-header .wg-rightSide .wg-profile .wg-toggleProfileMenu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wg-header .wg-rightSide .wg-profile .wg-profileMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  right: 0;
  width: 280px;
  opacity: 0;
  padding: var(--wg-spacing-01) var(--wg-spacing-01);
  background: var(--wg-surface);
  border-radius: var(--wg-radius-05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  z-index: 9999;
  transition: all 0.15s ease-in-out;
}
.wg-header .wg-rightSide .wg-profile .wg-profileMenu ul {
  margin: 0;
  padding: 0;
  width: 100%;
}
.wg-header .wg-rightSide .wg-profile .wg-profileMenu ul li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-03);
  width: 100%;
  color: var(--wg-neutral-10);
  padding: var(--wg-spacing-03) var(--wg-spacing-03);
  border-radius: var(--wg-radius-03);
  transition: all 0.15s ease-out;
}
.wg-header .wg-rightSide .wg-profile .wg-profileMenu ul li i {
  font-size: 20px;
}
.wg-header .wg-rightSide .wg-profile .wg-profileMenu ul li:hover {
  background: var(--wg-neutral-1);
  font-weight: 500;
}
.wg-header .wg-rightSide .wg-profile .wg-profileMenu ul li span {
  width: 100%;
}
.wg-header .wg-rightSide .wg-profile .wg-profileMenu.open {
  top: 60px;
  opacity: 1;
  pointer-events: inherit;
}
.wg-header .wg-rightSide .wg-profile .wg-avatar {
  width: 40px;
  height: 40px;
  border-radius: var(--wg-radius-100);
  overflow: hidden;
}
.wg-header .wg-rightSide .wg-profile .wg-avatar img {
  width: 100%;
  height: auto;
}
.wg-header .wg-rightSide .wg-profile .wg-userInfo {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-00);
  min-width: 100px;
  letter-spacing: 0.2px;
}
.wg-header .wg-rightSide .wg-profile .wg-userInfo .wg-username {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 280px;
  padding-right: var(--wg-spacing-04);
  color: var(--wg-neutral-10);
  font-size: var(--wg-font-02);
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wg-header .wg-rightSide .wg-profile .wg-userInfo .wg-username span {
  width: 100%;
  margin-right: var(--wg-spacing-01);
  text-transform: capitalize;
}
.wg-header .wg-rightSide .wg-profile .wg-userInfo .wg-role {
  color: var(--wg-neutral-5);
  font-size: var(--wg-font-01);
  font-weight: 500;
  text-transform: capitalize;
}
.wg-header .wg-rightSide .wg-profile i {
  font-size: var(--wg-font-06);
  color: var(--wg-neutral-10);
}
.wg-header .wg-rightSide .wg-profile:hover {
  background: var(--wg-neutral-1);
}
.wg-header .wg-rightSide .wg-profile.open {
  background: var(--wg-neutral-1);
}
.wg-header .wg-headerFakeOverlay {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 96px;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 9999;
}
.wg-header .wg-headerFakeOverlay.visible {
  display: block;
}
@media screen and (max-width: 1200px) {
  .wg-toggleMenu {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .wg-header {
    padding: 0 var(--wg-spacing-04);
  }
  .wg-header .wg-profile .wg-userInfo,
  .wg-header .wg-profile .wi-chevron-down {
    display: none !important;
  }
  .wg-header .wg-toggleMenu {
    display: block;
  }
}
.wg-skeletonContent {
  width: 100%;
  height: 100%;
  min-height: 5px;
  position: relative;
  overflow: hidden;
}
.wg-skeletonContent .wg-skeletonShimmerContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: skeletonReflection 2s infinite;
}
.wg-skeletonContent .wg-skeletonShimmerContent .wg-skeletonShimmer {
  width: 50%;
  height: 100%;
  background: var(--wg-neutral-12);
  opacity: 0.07;
  transform: skewX(-20deg);
}
.wg-skeletonContent .wg-skeleton {
  background: var(--wg-neutral-2);
  border-radius: var(--wg-radius-01);
}
.wg-skeletonContent .wg-skeleton.wg-skeleton_text {
  width: 100%;
  height: 12px;
}
.wg-skeletonContent .wg-skeleton.wg-skeleton_avatar {
  width: 100%;
  height: 100%;
  min-height: 36px;
  border-radius: var(--wg-radius-100);
}
.wg-skeletonContent .wg-skeleton.wg-skeleton_input {
  height: 44px;
}
.wg-skeletonContent.wg-skeleton_avatar {
  width: 100%;
  height: 100%;
  border-radius: var(--wg-radius-100);
}
@keyframes skeletonReflection {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}
.wg-noDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--wg-spacing-12);
  width: 100%;
  padding-top: var(--wg-spacing-12);
}
.wg-noDataContainer .wg-gridNoDataText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--wg-spacing-01);
}
.wg-noDataContainer .wg-gridNoDataText span {
  color: var(--wg-neutral-10);
}
.wg-noDataContainer .wg-gridNoDataText span:first-child {
  font-size: var(--wg-font-05);
  font-weight: 600;
}
.wg-noDataContainer .wg-gridNoDataText span:last-child {
  font-size: var(--wg-font-02);
  font-weight: 400;
}
.wg-navbar {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 296px;
  height: 100%;
  padding: var(--wg-spacing-06);
  background: var(--wg-background);
  border-right: 1px solid var(--wg-neutral-1);
  transition: width 0.15s ease-in-out;
  z-index: 99;
}
.wg-navbar ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.wg-navbar ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: var(--wg-spacing-01);
}
.wg-navbar ul li a.active {
  background: var(--wg-primary);
  color: var(--wg-onPrimary);
}
.wg-navbar ul li a.active:hover {
  background: var(--wg-primary);
  color: var(--wg-onPrimary);
}
.wg-navbar ul li a.active .wg-menuItemLabel {
  font-weight: 500;
}
.wg-navbar ul li.wg-subItemParent {
  flex-direction: column;
  position: relative;
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu {
  position: relative;
  border-radius: var(--wg-radius-04);
  height: fit-content;
  max-height: 50px;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu .wg-subItems {
  opacity: 0;
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu .wg-subItems li {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 100%;
  transition: all 0.15s ease-out;
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu .wg-subItems li a.active {
  background: none;
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu .wg-subItems li a.active .wg-menuItemLabel {
  color: var(--wg-primary);
  font-weight: 600;
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu .wg-subMenuTitle {
  display: none;
  font-size: var(--wg-font-03);
  font-weight: 600;
  color: var(--wg-neutral-10);
  padding-left: var(--wg-spacing-05);
  margin-bottom: var(--wg-spacing-02);
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu li {
  height: 36px;
  padding-left: var(--wg-spacing-08);
  pointer-events: none;
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu .wg-menuItemLabel {
  transition: font-weight 0.15s ease-out;
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu .wg-subItemArrow {
  transition: transform 0.15s;
  transform: rotate(0deg);
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu.open {
  max-height: 200px;
  background: var(--wg-neutral-1);
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu.open .wg-subItems {
  opacity: 1;
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu.open li {
  pointer-events: inherit;
}
.wg-navbar ul li.wg-subItemParent .wg-subMenu.open .wg-subItemArrow {
  transform: rotate(90deg);
}
.wg-navbar ul li.wg-subItemParent .wg-subItem {
  padding: var(--wg-spacing-03) var(--wg-spacing-03) var(--wg-spacing-03) var(--wg-spacing-08);
  border-radius: var(--wg-radius-03);
  transition: all 0.15s ease-in;
}
.wg-navbar ul li.wg-subItemParent .wg-subItem span {
  color: var(--wg-neutral-10);
}
.wg-navbar ul li.wg-subItemParent .wg-subItem:hover {
  background: var(--wg-neutral-1) !important;
}
.wg-navbar ul li.wg-subItemParent .wg-subItem:hover .wg-menuItemLabel {
  font-weight: 600;
}
.wg-navbar ul li a,
.wg-navbar ul li .wg-menuItem,
.wg-navbar ul li .wg-subItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-04);
  min-width: 50px;
  width: 100%;
  height: 50px;
  padding: 0 var(--wg-spacing-04);
  background: transparent;
  font-size: 14px;
  color: var(--wg-neutral-10);
  letter-spacing: 0.2px;
  border-radius: var(--wg-radius-100);
  cursor: pointer;
  transition: background 0.15s;
}
.wg-navbar ul li a .wg-menuItemLabel,
.wg-navbar ul li .wg-menuItem .wg-menuItemLabel,
.wg-navbar ul li .wg-subItem .wg-menuItemLabel {
  width: 100%;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wg-navbar ul li a .wg-subItemsIndicator,
.wg-navbar ul li .wg-menuItem .wg-subItemsIndicator,
.wg-navbar ul li .wg-subItem .wg-subItemsIndicator {
  position: absolute;
  left: 50%;
  bottom: 4px;
  width: 4px;
  height: 4px;
  background: var(--wg-neutral-3);
  opacity: 0;
  border-radius: var(--wg-radius-100);
}
.wg-navbar ul li a i,
.wg-navbar ul li .wg-menuItem i,
.wg-navbar ul li .wg-subItem i {
  font-size: 20px;
}
.wg-navbar ul li a:hover,
.wg-navbar ul li .wg-menuItem:hover,
.wg-navbar ul li .wg-subItem:hover {
  background: var(--wg-neutral-1);
}
.wg-navbar ul li a:hover .wg-menuItemLabel,
.wg-navbar ul li .wg-menuItem:hover .wg-menuItemLabel,
.wg-navbar ul li .wg-subItem:hover .wg-menuItemLabel {
  font-weight: 500;
}
.wg-navbar.wg-navbarCollapsed {
  width: 96px;
}
.wg-navbar.wg-navbarCollapsed .wg-navbarFakeOverlay.visible {
  display: block;
}
.wg-navbar.wg-navbarCollapsed a {
  padding-left: var(--wg-spacing-04);
}
.wg-navbar.wg-navbarCollapsed a .wg-menuItemLabel {
  display: none;
}
.wg-navbar.wg-navbarCollapsed .wg-subItemArrow {
  opacity: 0;
}
.wg-navbar.wg-navbarCollapsed .wg-subItemParent {
  width: 50px;
  height: 50px;
}
.wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu {
  position: relative;
  border-radius: var(--wg-radius-100);
}
.wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu .wg-subMenuTitle {
  display: block;
  margin-bottom: var(--wg-spacing-04);
}
.wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu .wg-subItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: -10px;
  left: 60px;
  width: 240px;
  opacity: 0;
  padding: var(--wg-spacing-03) 0;
  background: var(--wg-surface);
  border-radius: var(--wg-radius-05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  z-index: 9999;
  transition: all 0.15s ease-in-out;
}
.wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu .wg-subItems li {
  padding: var(--wg-spacing-03);
}
.wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu .wg-subItems .wg-menuItemLabel {
  display: block;
}
.wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu .wg-subItems .wg-subItem {
  background: transparent;
  height: 44px;
  padding-left: var(--wg-spacing-05);
}
.wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu.open {
  overflow: inherit;
}
.wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu.open .wg-subItems {
  top: 0;
  opacity: 1;
  pointer-events: inherit;
}
.wg-navbar .wg-navbarFakeOverlay {
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 96px;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 9999;
}
.wg-navbar .wg-navbarFakeOverlay.visible {
  display: none;
}
.wg-navbar.compact ul li a,
.wg-navbar.compact ul li .wg-menuItem,
.wg-navbar.compact ul li .wg-subItem {
  height: 40px;
}
.wg-navbar.compact ul li .wg-subMenu {
  max-height: 40px;
}
@media screen and (max-width: 1200px) {
  .wg-navbar {
    width: 96px;
  }
  .wg-navbar .wg-navbarFakeOverlay.visible {
    display: block;
  }
  .wg-navbar a {
    padding-left: var(--wg-spacing-04);
  }
  .wg-navbar a .wg-menuItemLabel {
    display: none;
  }
  .wg-navbar .wg-subItemArrow {
    opacity: 0;
  }
  .wg-navbar .wg-subItemParent {
    width: 50px;
    height: 50px;
  }
  .wg-navbar .wg-subItemParent .wg-subMenu {
    position: relative;
    border-radius: var(--wg-radius-100);
  }
  .wg-navbar .wg-subItemParent .wg-subMenu .wg-subMenuTitle {
    display: block;
  }
  .wg-navbar .wg-subItemParent .wg-subMenu .wg-subItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: -10px;
    left: 60px;
    width: 240px;
    opacity: 0;
    padding: var(--wg-spacing-01);
    background: var(--wg-surface);
    border-radius: var(--wg-radius-05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    pointer-events: none;
    z-index: 9999;
    transition: all 0.15s ease-in-out;
  }
  .wg-navbar .wg-subItemParent .wg-subMenu .wg-subItems li {
    height: 44px;
    padding: var(--wg-spacing-02) 0;
    margin: 0;
  }
  .wg-navbar .wg-subItemParent .wg-subMenu .wg-subItems li a {
    max-height: 44px;
  }
  .wg-navbar .wg-subItemParent .wg-subMenu .wg-subItems .wg-menuItemLabel {
    display: block;
  }
  .wg-navbar .wg-subItemParent .wg-subMenu .wg-subItems .wg-subItem {
    background: transparent;
    padding: var(--wg-spacing-03);
  }
  .wg-navbar .wg-subItemParent .wg-subMenu.open {
    overflow: inherit;
  }
  .wg-navbar .wg-subItemParent .wg-subMenu.open .wg-subItems {
    top: 0;
    opacity: 1;
    pointer-events: inherit;
  }
}
@media screen and (max-width: 768px) {
  .wg-navbar {
    width: 100%;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    pointer-events: none;
    overflow: hidden;
    transition: width 0.25s;
  }
  .wg-navbar.wg-navbarCollapsed {
    width: 100%;
    max-height: 100vh;
    padding: var(--wg-spacing-06);
    pointer-events: inherit;
  }
  .wg-navbar.wg-navbarCollapsed .wg-navbarFakeOverlay {
    display: none !important;
  }
  .wg-navbar.wg-navbarCollapsed a .wg-menuItemLabel {
    display: flex;
  }
  .wg-navbar.wg-navbarCollapsed .wg-subItemParent {
    width: 100%;
    height: inherit;
  }
  .wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu {
    border-radius: var(--wg-spacing-02);
  }
  .wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu .wg-subItems {
    display: inherit;
    position: inherit;
    top: inherit;
    left: inherit;
    background: inherit;
    box-shadow: inherit;
    padding-left: var(--wg-spacing-06);
  }
  .wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu .wg-subItems .wg-subItem {
    padding: 0 0 0 var(--wg-spacing-10);
  }
  .wg-navbar.wg-navbarCollapsed .wg-subItemParent .wg-subMenu .wg-subMenuTitle {
    display: none;
  }
  .wg-navbar.wg-navbarCollapsed .wg-subItemArrow {
    opacity: 1;
  }
}
.wg-section {
  width: 100%;
  padding: var(--wg-spacing-06);
  border-radius: var(--wg-radius-05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.wg-section.background {
  background: var(--wg-surface);
}
.wg-section .wg-sectionHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--wg-spacing-02);
  padding-bottom: var(--wg-spacing-06);
  color: var(--wg-neutral-12);
  border-bottom: 1px solid var(--wg-neutral-1);
  margin-bottom: var(--wg-spacing-06);
}
.wg-section .wg-sectionHeader .wg-sectionHeaderTitle .title {
  font-size: var(--wg-font-03);
  font-weight: 600;
  display: inline-block;
  margin-right: var(--wg-spacing-02);
  margin-bottom: var(--wg-spacing-01);
}
.wg-section .wg-sectionHeader .wg-sectionHeaderTitle .description {
  display: block;
  font-size: var(--wg-font-02);
  color: var(--wg-neutral-5);
  font-weight: 500;
}
.wg-section .wg-sectionHeader .wg-sectionHeaderComponent {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
.wg-toastContainer {
  width: inherit !important;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wg-toastContainer .Toastify__toast-theme--colored {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--wg-spacing-03);
  width: fit-content;
  min-height: inherit;
  padding: var(--wg-spacing-02);
  font-family: inherit;
  font-size: var(--wg-font-02);
  font-weight: 500;
  border-radius: var(--wg-radius-02);
}
.wg-toastContainer .Toastify__toast-theme--colored.Toastify__toast--info {
  background: var(--wg-neutral-12);
  color: var(--wg-onPrimary);
}
.wg-toastContainer .Toastify__toast-theme--colored.Toastify__toast--success {
  background: var(--wg-positive);
  color: var(--wg-onPrimary);
}
.wg-toastContainer .Toastify__toast-theme--colored.Toastify__toast--warning {
  background: var(--wg-warning);
  color: var(--wg-onPrimary);
}
.wg-toastContainer .Toastify__toast-theme--colored.Toastify__toast--error {
  background: var(--wg-negative);
  color: var(--wg-onPrimary);
}
.wg-toastContainer .Toastify__toast-theme--colored.Toastify__toast--error .Toastify__close-button:hover {
  background: rgba(255, 255, 255, 0.15);
}
.wg-toastContainer .Toastify__toast-theme--colored .Toastify__close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: inherit;
  padding: var(--wg-spacing-02);
  border-radius: var(--wg-radius-02);
}
.wg-toastContainer .Toastify__toast-theme--colored .Toastify__close-button:hover {
  background: rgba(255, 255, 255, 0.1);
}
.wg-tabContainer {
  display: flex;
  gap: var(--wg-spacing-03);
}
.wg-tabContainer .wg-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--wg-spacing-05);
  height: 36px;
  color: var(--wg-neutral-12);
  border: 1px solid var(--wg-neutral-1);
  cursor: pointer;
  border-radius: var(--wg-radius-02);
  font-weight: 500;
  text-wrap: nowrap;
  transition: background-color 0.15s ease-out;
}
.wg-tabContainer .wg-tab:hover {
  background: var(--wg-neutral-1);
}
.wg-tabContainer .wg-tab.active {
  background: var(--wg-primary);
  color: var(--wg-onPrimary);
}
.wg-toolbarContainer {
  display: flex;
  justify-content: center;
  position: fixed;
  right: 0;
  bottom: 24px;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}
.wg-toolbarContainer.visible {
  bottom: var(--wg-spacing-12);
  opacity: 1;
  pointer-events: inherit;
  animation: showToolbar 0.15s forwards;
}
.wg-toolbarContainer .wg-toolbar {
  display: flex;
  align-items: center;
  gap: 48px;
  padding: var(--wg-spacing-02) var(--wg-spacing-02) var(--wg-spacing-02) var(--wg-spacing-06);
  background: var(--wg-primary);
  color: var(--wg-onPrimary);
  border-radius: var(--wg-radius-100);
}
.wg-toolbarContainer .wg-toolbar .wg-toolbarSelectedRow {
  color: var(--wg-neutral-2);
}
.wg-toolbarContainer .wg-toolbar .wg-toolbarActions {
  display: flex;
  gap: 0;
}
@keyframes showToolbar {
  0% {
    bottom: var(--wg-spacing-09);
    opacity: 0;
    pointer-events: none;
  }
  100% {
    bottom: var(--wg-spacing-12);
    opacity: 1;
    pointer-events: inherit;
  }
}
.wg-tooltip {
  position: absolute;
  top: 100%;
  right: 0;
  width: max-content;
  max-width: 440px;
  margin-top: 4px;
  padding: var(--wg-spacing-03);
  background: var(--wg-primary);
  color: var(--wg-onPrimary);
  opacity: 0;
  pointer-events: none;
  z-index: 9;
  border-radius: var(--wg-radius-02);
  text-transform: none;
  transition: opacity 0.15s ease-in;
  animation: showTooltip 0.15s forwards;
}
@media screen and (max-width: 450px) {
  .wg-tooltip {
    position: absolute;
    width: calc(100vw - 32px);
    top: inherit;
    right: 0;
    bottom: 40px;
  }
}
@keyframes showTooltip {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.wg-login {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
  width: 100vw;
  height: 100vh;
  padding: var(--wg-spacing-01);
  background: var(--wg-surface);
}
.wg-login .wg-dataContainer,
.wg-login .wg-adsContainer {
  width: 50%;
  height: 100%;
}
.wg-login .wg-dataContainer img,
.wg-login .wg-adsContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--wg-radius-02);
}
.wg-login .wg-dataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: var(--wg-spacing-06) 96px;
}
.wg-login .wg-dataContainer .wg-data {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-width: 400px;
  height: 100%;
}
.wg-login .wg-dataContainer .wg-data .wg-logo {
  position: absolute;
  top: 0;
  left: 0;
}
.wg-login .wg-dataContainer .wg-data .wg-dataHeader {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-02);
}
.wg-login .wg-dataContainer .wg-data .wg-dataHeader .wg-title {
  font-size: var(--wg-font-06);
  font-weight: 700;
  color: var(--wg-neutral-12);
}
.wg-login .wg-dataContainer .wg-data .wg-dataHeader .wg-description {
  display: inline-block;
  margin-top: var(--wg-spacing-01);
  font-size: var(--wg-font-02);
  color: var(--wg-neutral-5);
}
.wg-login .wg-dataContainer .wg-data .wg-fields {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-05);
  margin-top: var(--wg-spacing-12);
}
.wg-login .wg-dataContainer .wg-data .wg-fields .wg-extraFields {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -8px;
  margin-bottom: var(--wg-spacing-03);
}
.wg-login .wg-dataContainer .wg-other {
  width: 100%;
  margin-top: var(--wg-spacing-05);
  text-align: center;
}
.wg-login .wg-loginDivider {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--wg-spacing-04);
  margin: var(--wg-spacing-11) 0;
  color: var(--wg-neutral-10);
  font-size: var(--wg-font-01);
  font-weight: 500;
}
.wg-login .wg-loginDivider span {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: var(--wg-neutral-1);
}
.wg-login .wg-logo {
  position: absolute;
  top: 32px;
  left: 32px;
}
.wg-googleButton {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
@media screen and (max-width: 1366px) {
  .wg-login .wg-dataContainer {
    padding: var(--wg-spacing-08) var(--wg-spacing-12);
  }
  .wg-login .wg-dataContainer .wg-data {
    max-width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .wg-login {
    display: flex;
    justify-content: center;
  }
  .wg-login .wg-dataContainer {
    width: 420px;
    padding: var(--wg-spacing-08) 0;
  }
  .wg-login .wg-dataContainer .wg-data {
    max-width: 100%;
  }
  .wg-login .wg-adsContainer {
    display: none;
  }
  .wg-login .wg-logo path {
    fill: #124EBB;
  }
}
@media screen and (max-width: 768px) {
  .wg-login .wg-dataContainer {
    width: 80%;
  }
  .wg-login .wg-dataContainer .wg-data {
    max-width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .wg-login {
    display: flex;
    justify-content: center;
  }
  .wg-login .wg-dataContainer {
    padding: var(--wg-spacing-08);
  }
  .wg-login .wg-dataContainer .wg-data {
    max-width: 100%;
  }
}
.wg-widgetsContainer {
  display: flex;
  width: 100%;
  gap: var(--wg-spacing-06);
}
.wg-widgetsContainer .wg-widgetChartContainer {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-04);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContent {
  display: flex;
  justify-content: space-between;
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContent .wg-widgetChartContentItem {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-01);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContent .wg-widgetChartContentItem .wg-widgetChartContentItemDescription {
  font-size: var(--wg-font-02);
  font-weight: 500;
  color: var(--wg-neutral-10);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContent .wg-widgetChartContentItem .wg-widgetChartContentItemAmount {
  font-size: var(--wg-font-02);
  font-weight: 600;
  color: var(--wg-neutral-12);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContent .wg-widgetChartContentItem:last-child {
  align-items: flex-end;
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContentFooter {
  font-weight: 500;
  color: var(--wg-neutral-12);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend {
  display: flex;
  gap: var(--wg-spacing-06);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem {
  position: relative;
  padding-left: var(--wg-spacing-04);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  background: var(--wg-neutral-1);
  border-radius: var(--wg-radius-100);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem.wg-widgetChartContentFooterLegendItem_0:before {
  background: var(--wg-chart01);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem.wg-widgetChartContentFooterLegendItem_1:before {
  background: var(--wg-chart02);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem.wg-widgetChartContentFooterLegendItem_2:before {
  background: var(--wg-chart03);
}
.wg-widgetsContainer .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem.wg-widgetChartContentFooterLegendItem_3:before {
  background: var(--wg-chart04);
}
@media screen and (max-width: 1200px) {
  .wg-widgetsContainer {
    flex-direction: column;
  }
}
.wg-detailPanelData.wg-detailPanelDataPayments .wg-detailPanelDataContent {
  height: calc(100% - 150px);
}
.wg-detailPanelData.wg-detailPanelDataPayments .wg-detailPanelDataHeader {
  margin-bottom: 0;
}
.wg-detailPanelData.wg-detailPanelDataPayments .wg-widgetChartContainer {
  border-bottom: 1px solid var(--wg-neutral-1);
  padding-bottom: var(--wg-spacing-06);
}
.wg-detailPanelData.wg-detailPanelDataPayments .wg-fields {
  margin-top: var(--wg-spacing-06);
}
.wg-detailPanelData .wg-feeList {
  margin: var(--wg-spacing-06) 0;
  border-top: 1px solid var(--wg-neutral-1);
}
.wg-detailPanelData .wg-feeList .wg-fee {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--wg-spacing-02);
  height: 62px;
  padding: 0 var(--wg-spacing-06);
  cursor: default;
  border-bottom: 1px solid var(--wg-neutral-1);
  border-radius: var(--wg-radius-01);
  transition: background 0.15s ease-out;
}
.wg-detailPanelData .wg-feeList .wg-fee:hover {
  background: var(--wg-neutral-1);
}
.wg-detailPanelData .wg-feeList .wg-fee .wg-feeRow {
  display: flex;
  justify-content: space-between;
}
.wg-detailPanelData .wg-feeList .wg-fee .wg-feeRow .type,
.wg-detailPanelData .wg-feeList .wg-fee .wg-feeRow .amount {
  color: var(--wg-neutral-12);
  font-weight: 600;
}
.wg-detailPanelData .wg-widgetChartContainer {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-04);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContent {
  display: flex;
  justify-content: space-between;
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContent .wg-widgetChartContentItem {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-01);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContent .wg-widgetChartContentItem .wg-widgetChartContentItemDescription {
  font-size: var(--wg-font-02);
  font-weight: 500;
  color: var(--wg-neutral-10);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContent .wg-widgetChartContentItem .wg-widgetChartContentItemAmount {
  font-size: var(--wg-font-02);
  font-weight: 600;
  color: var(--wg-neutral-12);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContent .wg-widgetChartContentItem:last-child {
  align-items: flex-end;
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContentFooter {
  font-weight: 500;
  color: var(--wg-neutral-12);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend {
  display: flex;
  gap: var(--wg-spacing-06);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem {
  position: relative;
  padding-left: var(--wg-spacing-04);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  background: var(--wg-neutral-1);
  border-radius: var(--wg-radius-100);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem.wg-widgetChartContentFooterLegendItem_0:before {
  background: var(--wg-chart01);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem.wg-widgetChartContentFooterLegendItem_1:before {
  background: var(--wg-chart02);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem.wg-widgetChartContentFooterLegendItem_2:before {
  background: var(--wg-chart03);
}
.wg-detailPanelData .wg-widgetChartContainer .wg-widgetChartContentFooter .wg-widgetChartContentFooterLegend .wg-widgetChartContentFooterLegendItem.wg-widgetChartContentFooterLegendItem_3:before {
  background: var(--wg-chart04);
}
.wg-fieldsRow {
  display: flex;
  gap: var(--wg-spacing-03);
  margin-top: var(--wg-spacing-03);
}
@media screen and (max-width: 768px) {
  .wg-detailPanelData.wg-detailPanelDataPayments .wg-detailPanelDataContent {
    padding: var(--wg-spacing-06) var(--wg-spacing-03);
    height: auto;
  }
  .wg-fieldsRow {
    flex-direction: column;
  }
}
.wg-notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--wg-spacing-04);
  width: 100%;
  height: 100%;
  margin-bottom: var(--wg-spacing-12);
}
.wg-notFound .wg-notFoundContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: var(--wg-spacing-02);
}
.wg-notFound .wg-notFoundContent .wg-notFoundTitle {
  font-size: var(--wg-font-08);
  color: var(--wg-neutral-12);
  font-weight: 600;
}
.wg-notFound .wg-notFoundContent .wg-notFoundDescription {
  font-size: var(--wg-font-03);
  color: var(--wg-neutral-10);
  font-weight: 400;
}
.wg-notFound .wg-notFoundButtons {
  display: flex;
  gap: var(--wg-spacing-02);
  margin-top: var(--wg-spacing-06);
}
.wg-importTemplate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: var(--wg-spacing-04);
  padding: var(--wg-spacing-06);
  margin-top: var(--wg-spacing-06);
  background: var(--wg-neutral-1);
  border-radius: var(--wg-radius-05);
}
.wg-importTemplate .wg-title {
  display: flex;
  align-items: center;
  gap: var(--wg-spacing-02);
  font-weight: 600;
}
.wg-importTemplate .wg-title i {
  font-size: 24px;
}
.wg-importTemplate .wg-description {
  margin-top: var(--wg-spacing-02);
  color: var(--wg-neutral-10);
}
.wg-importTemplate .wg-leftSide {
  width: 100%;
}
.wg-importError {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.wg-importError span {
  color: var(--wg-negative);
}
.wg-importedFileErrors {
  width: 100%;
}
.wg-importedFileErrors .wg-importedFileErrorsList {
  padding-top: var(--wg-spacing-06);
  max-height: 500px;
  overflow: auto;
}
.wg-importedFileErrors .wg-importErrorLine {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--wg-spacing-02 0);
}
.wg-importedFileErrors .wg-importErrorLine span:first-child {
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .wg-importTemplate {
    flex-direction: column;
    align-items: flex-start;
  }
}
.wg-detailPanelData {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-06);
  position: fixed;
  top: 0;
  right: 0;
  width: 860px;
  height: 100%;
  padding: var(--wg-spacing-06) 0 var(--wg-spacing-06) var(--wg-spacing-06);
  background: var(--wg-surface);
  border-radius: var(--wg-radius-05) 0 0 var(--wg-radius-05);
  z-index: 9999;
  transform: translateX(220px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s ease-out;
}
.wg-detailPanelData.visible {
  transform: translateX(0px);
  opacity: 1;
  pointer-events: inherit;
}
.wg-detailPanelData .wg-detailPanelDataHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--wg-spacing-04);
  align-items: center;
}
.wg-detailPanelData .wg-detailPanelDataHeader .wg-playerAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: var(--wg-radius-100);
  overflow: hidden;
}
.wg-detailPanelData .wg-detailPanelDataHeader .wg-playerAvatar::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--wg-primary);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
}
.wg-detailPanelData .wg-detailPanelDataHeader .wg-playerAvatar i {
  position: absolute;
  font-size: var(--wg-font-06);
  color: white;
  opacity: 0;
  pointer-events: none;
}
.wg-detailPanelData .wg-detailPanelDataHeader .wg-playerAvatar img {
  width: 100%;
  height: auto;
}
.wg-detailPanelData .wg-detailPanelDataHeader .wg-playerAvatar:hover::before {
  opacity: 0.8;
}
.wg-detailPanelData .wg-detailPanelDataHeader .wg-playerAvatar:hover i {
  opacity: 1;
}
.wg-detailPanelData .wg-detailPanelDataHeader .wg-detailPanelDataMetaData {
  flex: 1;
  font-size: var(--wg-font-03);
  font-weight: 600;
  color: var(--wg-neutral-12);
}
.wg-detailPanelData .wg-detailPanelDataHeader .wg-playerId {
  font-size: var(--wg-font-02);
  font-weight: 500;
  color: var(--wg-neutral-4);
}
.wg-detailPanelData .wg-detailPanelDataHeader .wg-mobileCloseButton {
  display: none;
}
.wg-detailPanelData .wg-detailPanelDataTab {
  width: calc(100% - 16px);
  border-bottom: 1px solid var(--wg-neutral-1);
  padding: var(--wg-spacing-01) 0 var(--wg-spacing-03) 0;
  overflow-x: auto;
  overflow-y: hidden;
}
.wg-detailPanelData .wg-detailPanelDataContent {
  max-height: 100%;
  overflow: auto;
  height: calc(100% - 224px);
  padding: 0 var(--wg-spacing-03) 72px var(--wg-spacing-01);
}
.wg-detailPanelData .wg-detailPanelDataContent .wg-detailPanelDataFieldsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-06);
  padding-top: var(--wg-spacing-06);
  padding-bottom: var(--wg-spacing-06);
}
.wg-detailPanelData .wg-detailPanelDataContent .wg-detailPanelDataFieldsContainer:last-child {
  padding-bottom: var(--wg-spacing-04);
}
.wg-detailPanelData .wg-detailPanelDataContent .wg-detailPanelDataFieldsContainer .wg-detailPanelDataFieldsContainerTitle {
  font-size: var(--wg-font-03);
  font-weight: 600;
  color: var(--wg-neutral-12);
}
.wg-detailPanelData .wg-detailPanelDataContent .wg-detailPanelDataFieldsContainer .wg-detailPanelDataFields {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-06);
}
.wg-detailPanelData .wg-detailPanelDataContent .wg-detailPanelDataFieldsContainer .wg-detailPanelDataFields .wg-detailPanelDataFieldsRow {
  display: flex;
  gap: var(--wg-spacing-03);
  padding-right: var(--wg-spacing-01);
}
.wg-detailPanelData .wg-detailPanelDataContent .wg-detailPanelDataFieldsContainer .wg-detailPanelDataFields .wg-detailPanelDataFieldsRow.fillSpace {
  justify-content: space-between;
}
.wg-detailPanelData .wg-detailPanelDataContent .wg-detailPanelDataFieldsContainer .wg-detailPanelDataFields .wg-detailPanelDataFieldsRow .wg-detailPanelDataFieldsRowThree {
  display: flex;
  gap: var(--wg-spacing-03);
  width: 100%;
}
.wg-detailPanelData .wg-detailPanelDataFooter {
  display: flex;
  justify-content: flex-end;
  gap: var(--wg-spacing-03);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: var(--wg-spacing-06);
  background: var(--wg-surface);
  border-top: 1px solid var(--wg-neutral-1);
  z-index: 99;
}
.wg-detailPanelData .wg-detailPanelDataFooter .wg-leftButton {
  display: flex;
  flex: 1;
}
.wg-detailPanelDataOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}
.wg-detailPanelDataOverlay.visible {
  opacity: 0.7;
  pointer-events: inherit;
}
@media screen and (max-width: 1000px) {
  .wg-detailPanelData {
    width: 100%;
  }
  .wg-detailPanelData .wg-detailPanelDataContent .wg-detailPanelDataFieldsContainer .wg-detailPanelDataFieldsRow {
    flex-direction: column;
  }
  .wg-detailPanelData .wg-detailPanelDataContent .wg-detailPanelDataFieldsContainer .wg-detailPanelDataFieldsRow.keepRow {
    flex-direction: row;
  }
  .wg-detailPanelData .wg-detailPanelDataContent .wg-detailPanelDataFieldsContainer .wg-detailPanelDataFieldsRow .hidden {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .wg-detailPanelData {
    display: block;
    height: 100%;
    padding: var(--wg-spacing-06) 0 var(--wg-spacing-06) var(--wg-spacing-03);
    overflow-y: auto;
    border-radius: 0;
  }
  .wg-detailPanelData .wg-detailPanelDataTab {
    margin: var(--wg-spacing-03) 0;
  }
  .wg-detailPanelData .wg-detailPanelDataContent {
    height: fit-content;
    max-height: inherit;
    overflow: inherit;
    margin-bottom: var(--wg-spacing-03);
  }
  .wg-detailPanelData .wg-detailPanelDataFooter {
    position: relative;
    flex-direction: column-reverse;
    z-index: 0;
  }
  .wg-detailPanelData .wg-detailPanelDataFooter .wg-leftButton {
    flex-direction: column;
  }
  .wg-detailPanelData .wg-detailPanelDataHeader .wg-mobileCloseButton {
    display: block;
  }
}
.wg-teamList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--wg-spacing-03);
  width: 100%;
}
.wg-teamList .wg-card {
  width: calc(25% - 12px);
}
.wg-teamList .wg-teamCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--wg-spacing-04);
  width: 100%;
}
.wg-teamList .wg-teamCard .wg-teamCardJersey {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 110px;
  height: 110px;
  background: var(--wg-neutral-1);
  border-radius: var(--wg-radius-100);
  overflow: hidden;
}
.wg-teamList .wg-teamCard .wg-teamCardJersey .wg-teamCardJerseyBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.075;
  z-index: 0;
}
.wg-teamList .wg-teamCard .wg-teamCardJersey .wg-teamCardJerseyBackground.skeleton {
  opacity: 1;
}
.wg-teamList .wg-teamCard .wg-teamCardJersey svg {
  z-index: 1;
  fill: #121212;
}
.wg-teamList .wg-teamCard .wg-teamCardInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--wg-spacing-01);
  width: 100%;
  text-align: center;
}
.wg-teamList .wg-teamCard .wg-teamCardInfo.skeleton {
  width: 220px;
  align-items: center;
}
.wg-teamList .wg-teamCard .wg-teamCardInfo .wg-teamCardName {
  width: 100%;
  max-width: 220px;
  color: var(--wg-neutral-10);
  font-size: var(--wg-font-02);
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wg-teamList .wg-teamCard .wg-teamCardInfo .wg-teamCardName.skeleton {
  width: 220px;
  align-items: center;
}
.wg-teamList .wg-teamCard .wg-teamCardInfo .wg-teamCardPlayers {
  width: 160px;
  color: var(--wg-neutral-4);
  font-size: var(--wg-font-01);
  font-weight: 500;
}
@media screen and (max-width: 1366px) {
  .wg-teamList .wg-card {
    width: calc(33.3% - 12px);
  }
}
@media screen and (max-width: 1000px) {
  .wg-teamList .wg-card {
    width: calc(33.3% - 12px);
  }
  .wg-teamList .wg-teamCard .wg-teamCardInfo .wg-teamCardName {
    width: 170px;
  }
}
@media screen and (max-width: 768px) {
  .wg-teamList .wg-card {
    width: calc(100% - 12px);
  }
  .wg-teamList .wg-teamCard .wg-teamCardInfo .wg-teamCardName {
    width: 220px;
  }
}
.wg-userList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--wg-spacing-03);
  width: 100%;
}
.wg-userList .wg-card {
  width: calc(25% - 12px);
}
.wg-userList .wg-userCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--wg-spacing-04);
  width: 100%;
}
.wg-userList .wg-userCard .wg-userCardAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 110px;
  height: 110px;
  background: var(--wg-neutral-1);
  border-radius: var(--wg-radius-100);
  overflow: hidden;
}
.wg-userList .wg-userCard .wg-userCardAvatar img {
  width: 100%;
}
.wg-userList .wg-userCard .wg-userCardAvatar .wg-userCardUserBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.075;
  z-index: 0;
}
.wg-userList .wg-userCard .wg-userCardAvatar .wg-userCardUserBackground.skeleton {
  opacity: 1;
}
.wg-userList .wg-userCard .wg-userCardAvatar svg {
  z-index: 1;
}
.wg-userList .wg-userCard .wg-userCardInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--wg-spacing-01);
  width: 100%;
  text-align: center;
}
.wg-userList .wg-userCard .wg-userCardInfo.skeleton {
  width: 220px;
  align-items: center;
}
.wg-userList .wg-userCard .wg-userCardInfo .wg-userCardName {
  width: 100%;
  max-width: 220px;
  color: var(--wg-neutral-10);
  font-size: var(--wg-font-02);
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wg-userList .wg-userCard .wg-userCardInfo .wg-userCardName.skeleton {
  width: 220px;
  align-items: center;
}
.wg-userList .wg-userCard .wg-userCardInfo .wg-userCardPlayers {
  width: 160px;
  color: var(--wg-neutral-4);
  font-size: var(--wg-font-01);
  font-weight: 500;
}
.wg-detailPanelData .wg-detailPanelDataContent {
  height: 100%;
}
@media screen and (max-width: 1366px) {
  .wg-userList .wg-card {
    width: calc(33.3% - 12px);
  }
}
@media screen and (max-width: 1000px) {
  .wg-userList .wg-card {
    width: calc(33.3% - 12px);
  }
  .wg-userList .wg-userCard .wg-userCardInfo .wg-userCardName {
    width: 170px;
  }
}
@media screen and (max-width: 768px) {
  .wg-userList .wg-card {
    width: calc(100% - 12px);
  }
  .wg-userList .wg-userCard .wg-userCardInfo .wg-userCardName {
    width: 220px;
  }
}
.wg-teamDetailJersey {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: var(--wg-radius-100);
  overflow: hidden;
}
.wg-teamDetailJersey .wg-teamDetailJerseyBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.075;
  z-index: 0;
}
.wg-searchPlayerList {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  margin-top: var(--wg-spacing-06);
}
.wg-searchPlayerList .wg-searchPlayerItem {
  border-top: 1px solid var(--wg-neutral-1);
}
.wg-searchPlayerList .wg-searchPlayerItem .wg-searchPlayerItemContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--wg-spacing-12);
  padding: 0 var(--wg-spacing-06) 0 var(--wg-spacing-03);
  height: 64px;
  background: var(--wg-surface);
  border-radius: var(--wg-radius-02);
  transition: background 0.15s ease-out;
}
.wg-searchPlayerList .wg-searchPlayerItem .wg-searchPlayerItemContent .wg-searchPlayerItemInfoGroup {
  display: flex;
  gap: var(--wg-spacing-03);
}
.wg-searchPlayerList .wg-searchPlayerItem .wg-searchPlayerItemContent .wg-searchPlayerItemInfoGroup .wg-searchPlayerItemAvatar {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: var(--wg-radius-100);
}
.wg-searchPlayerList .wg-searchPlayerItem .wg-searchPlayerItemContent .wg-searchPlayerItemInfoGroup .wg-searchPlayerItemAvatar img {
  width: 100%;
}
.wg-searchPlayerList .wg-searchPlayerItem .wg-searchPlayerItemContent .wg-searchPlayerItemMeta {
  display: flex;
  flex-direction: column;
}
.wg-searchPlayerList .wg-searchPlayerItem .wg-searchPlayerItemContent .wg-searchPlayerItemMeta span:first-child {
  font-weight: 500;
  font-size: var(--wg-font-02);
}
.wg-searchPlayerList .wg-searchPlayerItem .wg-searchPlayerItemContent .wg-searchPlayerItemMeta span:last-child {
  color: var(--wg-neutral-4);
  font-size: var(--wg-font-01);
  font-weight: 500;
}
.wg-searchPlayerList .wg-searchPlayerItem .wg-searchPlayerItemContent .wg-searchPlayerItemBirthdate {
  font-weight: 500;
}
.wg-searchPlayerList .wg-searchPlayerItem .wg-searchPlayerItemContent:hover {
  background: var(--wg-neutral-1);
}
.wg-teamColors .wg-colorTitle {
  display: flex;
  align-items: flex-start;
  gap: var(--wg-spacing-01);
  padding-bottom: var(--wg-spacing-02);
  font-size: var(--wg-font-02);
  font-weight: 600;
  color: var(--wg-neutral-10);
  text-transform: capitalize;
}
.wg-teamColors .wg-colorList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-03);
  margin-top: var(--wg-spacing-01);
}
.wg-teamColors .wg-colorList .wg-teamColor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-radius: var(--wg-radius-100);
}
.wg-teamColors .wg-colorList .wg-teamColor .wg-activeBullet {
  width: 16px;
  height: 16px;
  opacity: 0;
  border: 2px solid var(--wg-surface);
  border-radius: var(--wg-radius-100);
  transition: opacity 0.15s ease-out;
}
.wg-teamColors .wg-colorList .wg-teamColor.selected .wg-activeBullet {
  opacity: 1;
}
.wg-addPlayerButton {
  display: flex;
}
.wg-settingsContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: var(--wg-spacing-06);
  width: 100%;
}
.wg-settingsContent .wg-settingsNavigation ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-03);
  width: 100%;
}
.wg-settingsContent .wg-settingsNavigation ul .wg-settingsNavigationGroup {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-01);
}
.wg-settingsContent .wg-settingsNavigation ul .wg-settingsNavigationGroup li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-03);
  width: 100%;
  color: var(--wg-neutral-10);
  padding: var(--wg-spacing-03) var(--wg-spacing-03);
  border-radius: var(--wg-radius-100);
  cursor: default;
  transition: all 0.15s ease-out;
}
.wg-settingsContent .wg-settingsNavigation ul .wg-settingsNavigationGroup li:hover {
  background: var(--wg-neutral-1);
  font-weight: 500;
}
.wg-settingsContent .wg-settingsNavigation ul .wg-settingsNavigationGroup li.active {
  background: var(--wg-primary);
  color: var(--wg-onPrimary);
  font-weight: 500;
}
.wg-settingsContent .wg-settingsSelectedContent {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .wg-settingsContent {
    flex-direction: column;
  }
  .wg-settingsContent .wg-settingsContentSection {
    margin: 0 auto;
    width: initial !important;
    max-width: 100%;
    overflow-x: auto;
  }
  .wg-settingsContent .wg-settingsContentSection .wg-settingsNavigation ul {
    flex-direction: row;
    gap: var(--wg-spacing-01);
  }
  .wg-settingsContent .wg-settingsContentSection .wg-settingsNavigation ul .wg-divider {
    display: none;
  }
  .wg-settingsContent .wg-settingsContentSection .wg-settingsNavigation ul .wg-settingsNavigationGroup {
    flex-direction: row;
    padding-right: var(--wg-spacing-06);
  }
  .wg-settingsContent .wg-settingsContentSection .wg-settingsNavigation ul .wg-settingsNavigationGroup:last-child {
    display: none;
  }
  .wg-settingsContent .wg-settingsContentSection .wg-settingsNavigation ul li {
    width: 100%;
  }
}
.wg-preferences {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-06);
}
.wg-preferences .wg-fieldRow {
  display: flex;
  gap: var(--wg-spacing-03);
  width: 100%;
}
.wg-preferences .wg-preferencesActions {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--wg-spacing-04);
}
@media screen and (max-width: 768px) {
  .wg-preferences .wg-fieldRow {
    flex-direction: column;
    gap: var(--wg-spacing-08);
  }
}
.wg-profilePersonalInformation {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-06);
}
.wg-profilePersonalInformation .wg-avatarEdit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--wg-spacing-06);
}
.wg-profilePersonalInformation .wg-avatarEdit .wg-avatarContainer {
  width: 88px;
  max-height: 88px;
  border-radius: var(--wg-radius-100);
  overflow: hidden;
}
.wg-profilePersonalInformation .wg-avatarEdit .wg-avatarContainer img {
  width: 100%;
  overflow: hidden;
}
.wg-profilePersonalInformation .wg-avatarEdit .wg-avatarEditActions {
  display: flex;
  gap: var(--wg-spacing-03);
}
.wg-profilePersonalInformation .wg-fieldEdit {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: var(--wg-spacing-06);
}
.wg-profilePersonalInformation .wg-fieldEdit .wg-fieldRow {
  width: 100%;
  display: flex;
  gap: var(--wg-spacing-06);
}
.wg-profilePersonalInformation .wg-profilePersonalInformationActions {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--wg-spacing-04);
}
@media screen and (max-width: 768px) {
  .wg-profilePersonalInformation .wg-avatarEdit {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .wg-profilePersonalInformation .wg-avatarEdit .wg-avatarEditActions {
    flex-direction: column;
    width: 100%;
  }
  .wg-profilePersonalInformation .wg-fieldEdit .wg-fieldRow {
    flex-direction: column;
  }
}
.wg-security {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-06);
}
.wg-security .wg-authenticationCredentials {
  display: flex;
  justify-content: flex-start;
  gap: var(--wg-spacing-03);
}
.wg-security .wg-authenticationCredentials .wg-authenticationCredentialsField {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--wg-spacing-01);
  width: 100%;
}
.wg-security .wg-securityCredentialsActions {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--wg-spacing-06);
}
.wg-security .wg-twoFactorAuthentication {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-06);
}
.wg-security .wg-twoFactorAuthentication .wg-twoFactorAuthenticationRow {
  display: flex;
  align-items: center;
  width: 100%;
}
.wg-security .wg-twoFactorAuthentication .wg-twoFactorAuthenticationRow .wg-twoFactorAuthenticationInfo {
  display: flex;
  gap: var(--wg-spacing-04);
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.wg-security .wg-twoFactorAuthentication .wg-twoFactorAuthenticationRow .wg-twoFactorAuthenticationInfo .wg-twoFactorAuthenticationInfoGroup {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-01);
  width: 100%;
}
.wg-security .wg-twoFactorAuthentication .wg-twoFactorAuthenticationRow .wg-twoFactorAuthenticationInfo .wg-twoFactorAuthenticationInfoGroup .wg-twoFactorAuthenticationInfoTitle {
  font-size: var(--wg-font-02);
  font-weight: 500;
  text-transform: capitalize;
}
.wg-security .wg-twoFactorAuthentication .wg-twoFactorAuthenticationRow .wg-twoFactorAuthenticationInfo .wg-twoFactorAuthenticationInfoGroup .wg-twoFactorAuthenticationInfoDescription {
  font-size: var(--wg-font-01);
  color: var(--wg-neutral-4);
}
.wg-security .wg-twoFactorAuthentication .wg-twoFactorAuthenticationRow .wg-twoFactorAuthenticationInfo i {
  font-size: var(--wg-font-06);
}
@media screen and (max-width: 768px) {
  .wg-security .wg-authenticationCredentials {
    flex-direction: column;
    gap: var(--wg-spacing-08);
  }
}
.wg-resetPasswordContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: var(--wg-spacing-12);
  margin-top: var(--wg-spacing-12);
}
.wg-resetPasswordContainer .wg-resetPasswordFields {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-06);
  margin-bottom: var(--wg-spacing-04);
}
.wg-resetPasswordContainer .wg-passwordRequirementsList {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--wg-spacing-02);
}
.wg-resetPasswordContainer .wg-passwordRequirementsList .wg-passwordRequirement {
  position: relative;
  width: 50%;
  padding-left: var(--wg-spacing-05);
  padding-bottom: var(--wg-spacing-03);
}
.wg-resetPasswordContainer .wg-passwordRequirementsList .wg-passwordRequirement::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -10px;
  width: 10px;
  height: 10px;
  background: var(--wg-neutral-2);
  border-radius: var(--wg-radius-100);
  transition: all 0.15s;
}
.wg-resetPasswordContainer .wg-passwordRequirementsList .wg-passwordRequirement.match {
  font-weight: 500;
}
.wg-resetPasswordContainer .wg-passwordRequirementsList .wg-passwordRequirement.match::before {
  background: var(--wg-positive);
}
.wg-resetPasswordContainer .wg-resetPasswordActions {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .wg-resetPasswordSection {
    width: 100% !important;
  }
}
.wg-newUserContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: var(--wg-spacing-12);
  margin-top: var(--wg-spacing-12);
}
.wg-newUserContainer .wg-newUserFields {
  display: flex;
  flex-direction: column;
  gap: var(--wg-spacing-06);
  margin-bottom: var(--wg-spacing-04);
}
.wg-newUserContainer .wg-newUserActions {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .wg-newUserSection {
    width: 100% !important;
  }
}
* {
  outline: none;
  box-sizing: border-box;
}
html,
body {
  font-family: 'Mona Sans', sans-serif;
  scroll-behavior: smooth;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  background: var(--wg-background);
  color: var(--wg-neutral-10);
}
html.no-overflow,
body.no-overflow {
  overflow-y: hidden;
}
::-webkit-scrollbar {
  width: 16px;
}
:not([no-y-overflow])::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: var(--wg-radius-100);
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: var(--wg-neutral-4);
}
::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: var(--wg-radius-100);
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: red;
}
a {
  text-decoration: none;
  font-size: var(--wg-font-02);
  font-weight: 500;
  color: var(--wg-primary);
  transition: color 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
a.wg-decoration {
  text-decoration: underline;
}
a.wg-reverse {
  color: var(--wg-onPrimary);
}
a:hover,
a:focus {
  color: var(--wg-primaryLight);
}
a.disabled {
  pointer-events: none;
  color: var(--wg-neutral-4);
}
i {
  font-size: 18px;
}
input {
  appearance: none;
  -webkit-appearance: none;
}
.wg-required {
  height: 16px;
  font-size: var(--wg-font-04);
  color: var(--wg-negative);
}
.wg-divider {
  width: 100%;
  height: 1px;
  background: var(--wg-neutral-1);
}
.wg-app {
  background: var(--wg-background);
  width: 100%;
}
.wg-app .wg-appContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--wg-spacing-06);
  position: relative;
  top: 60px;
  left: 296px;
  width: calc(100vw - 312px);
  height: calc(100vh - 60px);
  padding: var(--wg-spacing-07) var(--wg-spacing-08);
  transition: width, left 0.15s ease-out;
}
.wg-app .wg-appContent.collapsed {
  width: calc(100vw - 112px);
  left: 96px;
}
.wg-app .wg-pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
}
.wg-app .wg-pageHeader .wg-pageTitle {
  font-size: var(--wg-font-05);
  font-weight: 700;
  color: var(--wg-neutral-12);
}
.wg-app .wg-pageHeader .wg-pageActions {
  display: flex;
  gap: var(--wg-spacing-03);
}
@media screen and (max-width: 1200px) {
  .wg-app .wg-appContent {
    width: calc(100vw - 112px);
    left: 96px;
    padding: var(--wg-spacing-08);
  }
  .wg-app.collapsed .wg-appContent {
    width: calc(100vw - 96px);
    left: 96px;
  }
}
@media screen and (max-width: 768px) {
  .wg-app .wg-appContent {
    left: 0;
    width: 100vw;
    padding: var(--wg-spacing-04);
  }
  .wg-app.collapsed {
    left: 0;
    width: 100vw;
  }
  .wg-app.collapsed .wg-appContent {
    left: 96px;
  }
}
@media screen and (max-width: 450px) {
  .wg-app .wg-appContent .wg-pageHeader {
    height: inherit;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: var(--wg-spacing-04);
  }
}
