// Fonts management

.wg-font-default {
  --wg-font-00: 10px;
  --wg-font-01: 12px;
  --wg-font-02: 14px;
  --wg-font-03: 16px;
  --wg-font-04: 18px;
  --wg-font-05: 20px;
  --wg-font-06: 24px;
  --wg-font-07: 28px;
  --wg-font-08: 32px;
  --wg-font-09: 36px;
  --wg-font-10: 40px;
  --wg-font-11: 44px;
}

.wg-font-compact {
  --wg-font-00: 10px;
  --wg-font-01: 12px;
  --wg-font-02: 14px;
  --wg-font-03: 16px;
  --wg-font-04: 18px;
  --wg-font-05: 20px;
  --wg-font-06: 24px;
  --wg-font-07: 28px;
  --wg-font-08: 32px;
  --wg-font-09: 36px;
  --wg-font-10: 40px;
  --wg-font-11: 44px;
}