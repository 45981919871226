// Spacing Refs

.wg-spacing-default {
  --wg-spacing-00: 2px;
  --wg-spacing-01: 4px;
  --wg-spacing-02: 8px;
  --wg-spacing-03: 12px;
  --wg-spacing-04: 16px;
  --wg-spacing-05: 20px;
  --wg-spacing-06: 24px;
  --wg-spacing-07: 28px;
  --wg-spacing-08: 32px;
  --wg-spacing-09: 36px;
  --wg-spacing-10: 40px;
  --wg-spacing-11: 44px;
  --wg-spacing-12: 48px;
}

.wg-spacing-compact {
  --wg-spacing-00: 2px;
  --wg-spacing-01: 4px;
  --wg-spacing-02: 8px;
  --wg-spacing-03: 12px;
  --wg-spacing-04: 16px;
  --wg-spacing-05: 20px;
  --wg-spacing-06: 24px;
  --wg-spacing-07: 28px;
  --wg-spacing-08: 32px;
  --wg-spacing-09: 36px;
  --wg-spacing-10: 40px;
  --wg-spacing-11: 44px;
  --wg-spacing-12: 48px;
}