// Light Scheme

.wg-theme-light {
  --wg-primary: #0D0C22;
  --wg-primaryDark: #04040D;
  --wg-primaryLight: #201E45;
  --wg-onPrimary: #FFFFFF;
  --wg-background: #F7F8FA;
  --wg-surface: #FFFFFF;
  --wg-surfaceVariant: #FAFAFE;
  --wg-neutral-0: #FFFFFF;
  --wg-neutral-1: #EFF1F4;
  --wg-neutral-2: #DBE0E8;
  --wg-neutral-3: #BCBEC9;
  --wg-neutral-4: #969FAE;
  --wg-neutral-5: #686576;
  --wg-neutral-6: #5D5777;
  --wg-neutral-10: #3E5060;
  --wg-neutral-12: #0D0C22;
  --wg-positive: #007654;
  --wg-positiveLight: #C9F1E8;
  --wg-warning: #A84F01;
  --wg-warningLight: #FFEFD6;
  --wg-negative: #CE0000;
  --wg-negativeLight: #FFDCDC;
  --wg-chart01: #F6C543;
  --wg-chart02: #4E13A6;
  --wg-chart03: #CF3169;
  --wg-chart04: #50B1D4;
  --wg-shadowLg: rgba(0,0,0,8%);
  --wg-overlay: rgba(0,0,0, .5);

  --toastify-color-info: var(--wg-primary);
  --toastify-color-warning: var(--wg-warning);
  --toastify-color-error: var(--wg-negative);
}

.wg-theme-dark {
  --wg-primary: #0D0C22;
  --wg-primaryDark: #04040D;
  --wg-primaryLight: #201E45;
  --wg-onPrimary: #FFFFFF;
  --wg-background: #0b0e1e;
  --wg-surface: #FFFFFF;
  --wg-surfaceVariant: #FAFAFE;
  --wg-neutral-0: #FFFFFF;
  --wg-neutral-1: #EFF1F4;
  --wg-neutral-2: #DBE0E8;
  --wg-neutral-3: #BCBEC9;
  --wg-neutral-4: #969FAE;
  --wg-neutral-5: #686576;
  --wg-neutral-6: #5D5777;
  --wg-neutral-10: #3E5060;
  --wg-neutral-12: #0D0C22;
  --wg-positive: #007654;
  --wg-positiveLight: #C9F1E8;
  --wg-warning: #A84F01;
  --wg-warningLight: #FFEFD6;
  --wg-negative: #CE0000;
  --wg-negativeLight: #FFDCDC;
  --wg-chart01: #F6C543;
  --wg-chart02: #4E13A6;
  --wg-chart03: #CF3169;
  --wg-chart04: #50B1D4;
  --wg-shadowLg: rgba(0,0,0,8%);
  --wg-overlay: rgba(0,0,0, .5);

  --toastify-color-info: var(--wg-primary);
  --toastify-color-warning: var(--wg-warning);
  --toastify-color-error: var(--wg-negative);
}