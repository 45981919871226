@font-face {
  font-family: 'WeasyStudio-Icons';
  src:
    url('WeasyStudio-Icons.ttf?spgf4r') format('truetype'),
    url('WeasyStudio-Icons.woff?spgf4r') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'WeasyStudio-Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wi-bell:before {
  content: "\e900";
}
.wi-binoculars:before {
  content: "\e901";
}
.wi-briefcase:before {
  content: "\e902";
}
.wi-calculator:before {
  content: "\e903";
}
.wi-calendar:before {
  content: "\e904";
}
.wi-checkmark:before {
  content: "\e905";
}
.wi-chevron-down:before {
  content: "\e906";
}
.wi-chevron-left:before {
  content: "\e907";
}
.wi-chevron-right:before {
  content: "\e908";
}
.wi-chevron-top:before {
  content: "\e909";
}
.wi-close:before {
  content: "\e90a";
}
.wi-delete:before {
  content: "\e90b";
}
.wi-document:before {
  content: "\e90c";
}
.wi-document-error:before {
  content: "\e90d";
}
.wi-dots_horizontal:before {
  content: "\e90e";
}
.wi-download:before {
  content: "\e90f";
}
.wi-duplicate:before {
  content: "\e910";
}
.wi-excel .path1:before {
  content: "\e911";
  color: rgb(22, 145, 84);
}
.wi-excel .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(24, 72, 42);
}
.wi-excel .path3:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(12, 128, 69);
}
.wi-excel .path4:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(23, 71, 42);
}
.wi-excel .path5:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(41, 194, 127);
}
.wi-excel .path6:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(39, 102, 63);
}
.wi-excel .path7:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(25, 172, 101);
}
.wi-excel .path8:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(18, 150, 82);
}
.wi-excel .path9:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(12, 114, 56);
}
.wi-excel .path10:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.wi-filters:before {
  content: "\e91b";
}
.wi-finance:before {
  content: "\e91c";
}
.wi-flag:before {
  content: "\e91d";
}
.wi-google .path1:before {
  content: "\e91e";
  color: rgb(66, 133, 244);
}
.wi-google .path2:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.wi-google .path3:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.wi-google .path4:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.wi-health:before {
  content: "\e922";
}
.wi-home:before {
  content: "\e923";
}
.wi-import:before {
  content: "\e924";
}
.wi-language:before {
  content: "\e925";
}
.wi-levels:before {
  content: "\e926";
}
.wi-locker:before {
  content: "\e927";
}
.wi-login:before {
  content: "\e928";
}
.wi-logout:before {
  content: "\e929";
}
.wi-menu-closed:before {
  content: "\e92a";
}
.wi-menu-open:before {
  content: "\e92b";
}
.wi-pdf .path1:before {
  content: "\e92c";
  color: rgb(229, 37, 42);
}
.wi-pdf .path2:before {
  content: "\e92d";
  margin-left: -0.75em;
  color: rgb(237, 103, 106);
}
.wi-pdf .path3:before {
  content: "\e92e";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
}
.wi-pdf .path4:before {
  content: "\e92f";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
}
.wi-pdf .path5:before {
  content: "\e930";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
}
.wi-plus:before {
  content: "\e931";
}
.wi-question_mark:before {
  content: "\e932";
}
.wi-registry:before {
  content: "\e933";
}
.wi-save:before {
  content: "\e934";
}
.wi-search:before {
  content: "\e935";
}
.wi-send_mail:before {
  content: "\e936";
}
.wi-settings:before {
  content: "\e937";
}
.wi-shield:before {
  content: "\e938";
}
.wi-slider:before {
  content: "\e939";
}
.wi-smile:before {
  content: "\e93a";
}
.wi-soccer_ball:before {
  content: "\e93b";
}
.wi-soccer_field:before {
  content: "\e93c";
}
.wi-sort_asc:before {
  content: "\e93d";
}
.wi-sort_ascending:before {
  content: "\e93e";
}
.wi-sort_default:before {
  content: "\e93f";
}
.wi-sort_desc:before {
  content: "\e940";
}
.wi-sort_descending:before {
  content: "\e941";
}
.wi-support:before {
  content: "\e942";
}
.wi-theme:before {
  content: "\e943";
}
.wi-user:before {
  content: "\e944";
}
.wi-user-data:before {
  content: "\e945";
}
.wi-warning:before {
  content: "\e946";
}
